/*--------------------------------------------------------------
## Color Variable
----------------------------------------------------------------*/
$white: #ffffff;
$black: #000;
$primary: #15335b;
$secondary: rgba($primary, 0.52);
$ternary: #163c6680;
$border: #eaeaea;
$gray: #fafafa;
$accent: #2093d1;
