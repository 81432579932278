/*--------------------------------------------------------------
22. Funfact
----------------------------------------------------------------*/
.cs_funfact_1_wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 80px 50px 90px;
  @media (max-width: 1400px) {
    padding: 60px 30px 70px;
  }
}
.cs_funfact.cs_style_1 {
  max-width: 20%;
  padding: 20px 30px;
  flex: none;
  @media (max-width: 1400px) {
    padding: 10px 15px;
  }
  @media (max-width: 1199px) {
    padding: 20px 20px;
    max-width: 33.333333%;
  }
  @media (max-width: 767px) {
    padding: 20px 20px;
    max-width: 50%;
  }
  @media (max-width: 575px) {
    max-width: 100%;
    padding: 15px 0;
  }
  
  .cs_funfact_number {
    margin-bottom: 8px;
  }
}
