/*--------------------------------------------------------------
11. Hero
----------------------------------------------------------------*/

.cs_hero.cs_style_1 {
  height: 1000px;
  margin-top: -75px;
  padding-bottom: 110px;
  position: relative;

  @media (max-width: 1700px) {
    height: 900px;
  }
  @media (max-width: 1480px) {
    height: 1115px;
  }
  @media (max-width: 1380px) {
    height: 980px;
  }
  @media (max-width: 1300px) {
    height: 1010px;
  }
  @media (max-width: 991px) {
    height: initial;
  }

  .cs_hero_text {
    position: relative;
    z-index: 1;
    margin-top: 30px;
  }

  .cs_hero_wrap {
    height: 100%;
    width: 100%;
    padding-top: 220px;
    position: relative;

    @media (max-width: 1300px) {
      padding-top: 220px;
    }
    @media (max-width: 1199px) {
      padding-top: 150px;
    }

    > .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .cs_hero_title {
    margin-bottom: 29px;
    max-width: 900px;

    @media (max-width: 1700px) {
      max-width: 575px;
    }
    @media (max-width: 1199px) {
      max-width: 580px;
    }
    @media (max-width: 1199px) {
      margin-bottom: 20px;
    }
  }

  .cs_hero_subtitle {
    max-width: 700px;
    margin-bottom: 29px;

    @media (max-width: 1700px) {
      max-width: 575px;
    }
    @media (max-width: 1199px) {
      max-width: 580px;
    }
    @media (max-width: 991px) {
      margin-bottom: 35px;
    }
  }

  .cs_hero_info_wrap {
    padding: 70px 140px;
    margin-bottom: -110px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    position: relative;
    z-index: 2;

    @media (max-width: 1700px) {
      padding: 70px;
    }
    @media (max-width: 1199px) {
      padding: 70px 25px;
      gap: 24px;
    }
    @media (max-width: 991px) {
      justify-content: center;
    }
    @media (max-width: 767px) {
      justify-content: space-between;
    }
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 40px 25px;
    }
  }

  .cs_hero_info_title {
    font-size: 16px;
    line-height: 1.6em;
    margin-bottom: 4px;
  }

  .cs_hero_info_subtitle {
    line-height: 1.3em;
    margin: 0;
  }

  .cs_hero_info_icon {
    height: 70px;
    width: 70px;
    padding: 18px;

    @media (max-width: 1199px) {
      height: 60px;
      width: 60px;
      padding: 15px;
    }

    img {
      filter: brightness(0) invert(1);
    }
  }

  .cs_hero_info {
    gap: 24px;

    @media (max-width: 1199px) {
      gap: 15px;
    }
  }

  .cs_hero_img {
    position: absolute;
    bottom: 0px;
    right: 6%;
    max-height: 86%;

    @media (max-width: 1700px) {
      right: 4%;
      max-height: 84%;
    }
    @media (max-width: 1480px) {
      right: 1%;
    }
    @media (max-width: 1300px) {
      max-height: 82%;
      right: 0;
    }
    @media (max-width: 1199px) {
      max-height: 72%;
    }
    @media (max-width: 991px) {
      max-height: 100%;
      position: initial;
      margin-bottom: -100px;
      margin-top: 20px;
    }
    @media (max-width: 575px) {
      margin-bottom: -55px;
    }
  }
}
