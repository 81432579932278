/*--------------------------------------------------------------
3. Spacing
----------------------------------------------------------------*/
@for $i from 0 through 200 {
  .cs_height_#{$i} {
    height: $i + px;
  }
}

@media screen and (max-width: 2540px) {
  @for $i from 0 through 200 {
    .cs_height_xl_#{$i} {
      height: $i + px;
    }
  }
}
@media screen and (max-width: 991px) {
  @for $i from 0 through 200 {
    .cs_height_lg_#{$i} {
      height: $i + px;
    }
  }
}
